//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n from 'i18next';

import DefaultTypes     from '@components/DefaultTypes';
import PropTypes        from '@components/PropTypes';
import Button           from '@stateless/atomic/Button';
import LabeledInput     from '@stateless/atomic/LabeledInput';
import LabeledInputType from '@stateless/atomic/LabeledInput/LabeledInputType';
import TextInputType    from '@stateless/atomic/TextInput/TextInputType';

import styles from './styles.module.scss';

const propTypes = {
    authenticate:    PropTypes.func,
    password:        PropTypes.string,
    passwordChanged: PropTypes.func,
};

const StagingLoginOverlay = ({
    authenticate    = DefaultTypes.func,
    password        = null,
    passwordChanged = DefaultTypes.func,
}) => {
    function onAuthenticateClicked() {
        authenticate();
    }

    function onPasswordChanged(value) {
        passwordChanged({
            password: value,
        });
    }

    return (
        <div className={styles.stagingLoginOverlayContainer}>
            <div className={styles.stagingLoginOverlay}>
                <LabeledInput
                    label={I18n.t('stagingPasswordPlaceholder')}
                    onChange={onPasswordChanged}
                    type={LabeledInputType.lightWithoutLabel}
                    value={password}
                    textInputType={TextInputType.password}
                />
                <Button
                    onClick={onAuthenticateClicked}
                    text={I18n.t('login')}
                />
            </div>
        </div>
    );
};

StagingLoginOverlay.propTypes = propTypes;

export default StagingLoginOverlay;
