//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { takeLatest } from 'redux-saga/effects';
import { put }        from 'redux-saga/effects';
import { call }       from 'redux-saga/effects';

import * as Api        from '@api/index';
import Hydra           from '@helper/Hydra';
import Notification    from '@helper/Notification';
import { NewsActions } from '@slices/news';

function* fetchNews() {
    const response = yield call(Api.context.news.fetchNews);

    if (response.ok) {
        const news = Hydra.getMembersFromResponse(response.data);

        yield put(NewsActions.fetchNewsSucceeded({
            news,
        }));
    } else {
        yield put(NewsActions.fetchNewsFailed());
    }
}

function* fetchNewsFailed() {
    Notification.error('fetchNews.error');
}

const callSagas = () => {
    return [
        // @formatter:off
        takeLatest([NewsActions.fetchNews().type],       fetchNews),
        takeLatest([NewsActions.fetchNewsFailed().type], fetchNewsFailed),
        // @formatter:on
    ];
};

export default {
    callSagas,
};
