//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';

import { ThemeProvider } from 'styled-components';

import PropTypes   from '@components/PropTypes';
import Url         from '@helper/Url';
import useAppTheme from '@hooks/AppTheme';

const propTypes = {
    children: PropTypes.children,
};

const AppThemeProvider = ({
    children = null,
}) => {
    const appTheme   = useAppTheme();
    const setFavicon = (iconUrl) => {
        let link = document.querySelector('link[rel~=\'icon\']');

        if (!link) {
            link     = document.createElement('link');
            link.rel = 'icon';

            document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = iconUrl;
    };

    useEffect(() => {
        const appThemeFaviconPath = appTheme?.favicon?.path;

        if (!appThemeFaviconPath) {
            return;
        }

        const faviconUrl = Url.backendImage(appThemeFaviconPath);

        setFavicon(faviconUrl);
    }, [appTheme]);

    return (
        <ThemeProvider theme={appTheme}>
            {children}
        </ThemeProvider>
    );
};

AppThemeProvider.propTypes = propTypes;

export default AppThemeProvider;
