//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React             from 'react';
import { useState }      from 'react';
import { createContext } from 'react';
import { useContext }    from 'react';

import _ from 'lodash';

import PropTypes      from '@components/PropTypes';
import TooltipBundler from '@connected/TooltipProvider/TooltipBundler';

const TooltipContext = createContext({
    addTooltip:     _.noop,
    renderTooltips: _.noop,
});

const propTypes = {
    children: PropTypes.children,
};

const TooltipProvider = ({ children }) => {
    const [tooltipsBundlers, setTooltipBundlers] = useState([]);
    const addTooltip                             = (infoText, tooltipId) => {
        if (
            !tooltipId ||
            !infoText
        ) {
            return null;
        }

        let tooltipBundler = tooltipsBundlers.find((bundler) => bundler.tooltipId === tooltipId);

        if (!tooltipBundler) {
            tooltipBundler = new TooltipBundler({
                infoText,
                tooltipId,
            });

            setTooltipBundlers([...tooltipsBundlers, tooltipBundler]);
        }
        return tooltipBundler.renderInfoIcon();
    };
    const renderTooltips                         = () => {
        return tooltipsBundlers.map((bundler) => bundler.renderInfoTooltip());
    };

    return (
        <TooltipContext.Provider
            value={{
                addTooltip,
                renderTooltips,
            }}
        >
            {children}
        </TooltipContext.Provider>
    );
};

TooltipProvider.propTypes = propTypes;

export default TooltipProvider;

export const useTooltipContext = () => useContext(TooltipContext);
