//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import { useState } from 'react';

import I18n                           from 'i18next';
import _                              from 'lodash';
import { useDispatch }                from 'react-redux';
import { useSelector }                from 'react-redux';
import { string }                     from 'yup';
import { number }                     from 'yup';
import { object as objectValidation } from 'yup';

import ConfigurationValues        from '@constants/ConfigurationValues';
import Limit                      from '@constants/Limit';
import ObjectFields               from '@constants/ObjectFields';
import { getTaxOptions }          from '@constants/PropertyTransferTax';
import { getPropertyTypeOptions } from '@constants/PropertyType';
import Address                    from '@helper/Address';
import Notification               from '@helper/Notification';
import useContextTranslator       from '@hooks/ContextTranslator';
import { useObject }              from '@slices/object';
import Button                     from '@stateless/atomic/Button';
import ButtonType                 from '@stateless/atomic/Button/ButtonType';
import CurrencyInputWrapper       from '@stateless/atomic/CurrencyInputWrapper';
import DropDown                   from '@stateless/atomic/DropDown';
import DropDownType               from '@stateless/atomic/DropDown/DropDownType';
import Headline                   from '@stateless/atomic/Headline';
import HeadlineType               from '@stateless/atomic/Headline/HeadlineType';
import IconType                   from '@stateless/atomic/Icon/IconType';
import LabeledInput               from '@stateless/atomic/LabeledInput';
import LabeledInputType           from '@stateless/atomic/LabeledInput/LabeledInputType';
import TextInputType              from '@stateless/atomic/TextInput/TextInputType';
import LabeledWrapper             from '@stateless/composed/LableledWrapper';
import LayoutScreen               from '@stateless/composed/LayoutScreen';
import PageTitle                  from '@stateless/composed/PageTitle';
import TextSliderInput            from '@stateless/composed/TextSliderInput';
import selectEditObject           from '@store/selectors/object';

import styles from './styles.module.scss';

const EditObjectScreen = () => {
    const translator                    = useContextTranslator('screens.editObjectScreen');
    const dispatch                      = useDispatch();
    const object                        = useSelector(selectEditObject);
    const objectAction                  = useObject(dispatch);
    const isNewObject                   = !_.get(object, 'iri');
    const buttonText                    = (
        isNewObject ?
            'createObject' :
            'saveObject'
    );
    const titleText                     = (
        isNewObject ?
            'createTitle' :
            'editTitle'
    );
    const pageTitle                     = (
        isNewObject ?
            'pageCreateTitle' :
            'pageEditTitle'
    );
    const [validations, setValidations] = useState({});
    const objectFormSchema              = objectValidation({
        [ObjectFields.street]:                string().max(Limit.maxStringLength),
        [ObjectFields.houseNumber]:           string().max(Limit.maxStringLength),
        [ObjectFields.postalCode]:            string().max(Limit.maxStringLength),
        [ObjectFields.city]:                  string().max(Limit.maxStringLength),
        [ObjectFields.description]:           string().required().max(Limit.maxStringLength),
        [ObjectFields.landShare]:             string(),
        [ObjectFields.propertyManagerCosts]:  number(),
        [ObjectFields.unitManagerCosts]:      number(),
        [ObjectFields.reserves]:              number(),
        [ObjectFields.realEstateTransferTax]: number(),
        [ObjectFields.notaryFees]:            number(),
        [ObjectFields.mediationCosts]:        number(),
        [ObjectFields.yearOfConstruction]:    number().notRequired().min(1000).max(9999),
        [ObjectFields.type]:                  string(),
    });

    function onUpdateData(path) {
        return (value) => {
            const changeSet = {};

            if (_.has(validations, path)) {
                setValidations(_.omit(validations, path));
            }

            _.set(changeSet, path, value);
            objectAction.editObjectData({
                edit: changeSet,
            });
        };
    }

    function isFormValid() {
        try {
            objectFormSchema.validateSync(
                object,
                {
                    abortEarly: false,
                },
            );
            setValidations({});
            return true;
        } catch (validationError) {
            setValidations(validationError.inner.reduce((errors, current) => {
                return {
                    ...errors,
                    [current.path]: current.message,
                };
            }, {}));

            return false;
        }
    }

    function onChangeStreetAndHouseNumber(value) {
        const [streetName, houseNumber] = Address.splitStreetAndHouseNumber(value);

        onUpdateData(ObjectFields.street)(streetName);
        onUpdateData(ObjectFields.houseNumber)(houseNumber);
    }

    function renderTextInput({
        label,
        field,
        value,
        required,
        onChange,
        type = LabeledInputType.light,
    }) {
        return (
            <LabeledInput
                label={label}
                type={type}
                error={_.has(validations, field)}
                required={required}
                value={value ?? _.get(object, field)}
                onChange={onChange ?? onUpdateData(field)}
            />
        );
    }

    function renderNumberInput({
        label,
        field,
        value,
        required,
        onChange,
        type = LabeledInputType.light,
    }) {
        return (
            <LabeledInput
                label={label}
                type={type}
                error={_.has(validations, field)}
                required={required}
                textInputType={TextInputType.number}
                value={value ?? _.get(object, field)}
                onChange={onChange ?? onUpdateData(field)}
            />
        );
    }

    function renderPercentInput({
        label,
        field,
        value,
        required,
        onChange,
        infoText,
        type = LabeledInputType.light,
    }) {
        return (
            <LabeledInput
                label={label}
                type={type}
                required={required}
                error={_.has(validations, field)}
                infoText={infoText}
                tooltipId={field}
                input={
                    <CurrencyInputWrapper
                        value={value ?? _.get(object, field)}
                        onChange={onChange ?? onUpdateData(field)}
                        required={required}
                        intlConfig={null}
                        suffix={I18n.t('format.percentSuffix')}
                        formatToFixed={false}
                    />
                }
            />
        );
    }

    function renderCurrencyInput({
        label,
        field,
        value,
        required,
        onChange,
        bottomInfoText,
        type = LabeledInputType.light,
    }) {
        return (
            <LabeledInput
                label={label}
                type={type}
                required={required}
                error={_.has(validations, field)}
                bottomInfoText={bottomInfoText}
                input={
                    <CurrencyInputWrapper
                        value={value ?? _.get(object, field)}
                        onChange={onChange ?? onUpdateData(field)}
                        required={required}
                    />
                }
            />
        );
    }

    function onUpdateClicked() {
        if (isFormValid()) {
            objectAction.saveObject();
        } else {
            const prefix = (
                isNewObject ?
                    'createObject' :
                    'updateObject'
            );

            Notification.error(`${prefix}.validationError`);
        }
    }

    function onLandShareChanged(value) {
        onUpdateData(ObjectFields.landShare)(_.toNumber(value));
    }

    return (
        <>
            <PageTitle
                title={translator.t(pageTitle)}
            />
            <LayoutScreen>
                <div className={styles.editObjectScreen}>
                    <Headline
                        type={HeadlineType.headline1}
                        title={translator.t(titleText)}
                    />
                    <div className={styles.contentColumns}>
                        <div>
                            <LabeledWrapper
                                label={translator.t('objectAddress')}
                                columns={1}
                            >
                                {renderTextInput({
                                    label:    translator.t('objectName'),
                                    field:    ObjectFields.description,
                                    required: true,
                                })}
                                {renderTextInput({
                                    label:    translator.t('streetAndNumber'),
                                    value:    Address.combineStreetAndHouseNumber(
                                        _.get(object, ObjectFields.street),
                                        _.get(object, ObjectFields.houseNumber),
                                    ),
                                    onChange: onChangeStreetAndHouseNumber,
                                })}
                                <div className={styles.zipAndCity}>
                                    {renderTextInput({
                                        label: translator.t('zipCode'),
                                        field: ObjectFields.postalCode,
                                    })}
                                    {renderTextInput({
                                        label: translator.t('city'),
                                        field: ObjectFields.city,
                                    })}
                                </div>
                            </LabeledWrapper>
                            <LabeledWrapper
                                label={translator.t('objectInformation')}
                                columns={2}
                            >
                                <DropDown
                                    dropDownType={DropDownType.whiteWithLabel}
                                    label={translator.t('buildingType')}
                                    options={getPropertyTypeOptions()}
                                    value={_.get(object, ObjectFields.buildingType)}
                                    onChange={onUpdateData(ObjectFields.buildingType)}
                                />
                                {renderNumberInput({
                                    label:    translator.t('yearOfConstruction'),
                                    field:    ObjectFields.yearOfConstruction,
                                    required: false,
                                })}
                            </LabeledWrapper>
                            <LabeledWrapper
                                label={translator.t('ownerCosts')}
                                columns={3}
                            >
                                {renderCurrencyInput({
                                    label:          translator.t('houseManagement'),
                                    field:          ObjectFields.propertyManagerCosts,
                                    bottomInfoText: translator.t('taxInfoText'),
                                })}
                                {renderCurrencyInput({
                                    label:          translator.t('rentManagement'),
                                    field:          ObjectFields.unitManagerCosts,
                                    bottomInfoText: translator.t('taxInfoText'),
                                })}
                                {renderCurrencyInput({
                                    label:          translator.t('reserves'),
                                    field:          ObjectFields.reserves,
                                    bottomInfoText: translator.t('reserveInfoText'),
                                })}
                            </LabeledWrapper>
                        </div>
                        <div>
                            <LabeledWrapper
                                label={translator.t('buySideCosts')}
                                columns={2}
                            >
                                <DropDown
                                    dropDownType={DropDownType.whiteWithLabel}
                                    label={translator.t('propertyTransferTax')}
                                    options={getTaxOptions()}
                                    value={_.get(object, ObjectFields.realEstateTransferTax)}
                                    onChange={onUpdateData(ObjectFields.realEstateTransferTax)}
                                />
                                {renderPercentInput({
                                    label:    translator.t('notaryCosts'),
                                    field:    ObjectFields.notaryFees,
                                    infoText: translator.t('notaryCostsInfoText'),
                                })}
                                {renderPercentInput({
                                    label:    translator.t('mediationCosts'),
                                    field:    ObjectFields.mediationCosts,
                                    infoText: translator.t('mediationCostsInfoText'),
                                })}
                            </LabeledWrapper>
                            <LabeledWrapper
                                label={translator.t('buySideCosts')}
                                columns={1}
                            >
                                <TextSliderInput
                                    minimumValue={ConfigurationValues.landShare.minimumValue}
                                    step={ConfigurationValues.landShare.step}
                                    maximumValue={ConfigurationValues.landShare.maximumValue}
                                    value={_.get(object, ObjectFields.landShare)}
                                    fixedDecimalLength={0}
                                    label={translator.t('groundAndBuilding')}
                                    onChange={onLandShareChanged}
                                />
                            </LabeledWrapper>
                        </div>
                    </div>
                    <Button
                        type={ButtonType.edgy}
                        iconLeft={IconType.circlePlus}
                        text={translator.t(buttonText)}
                        onClick={onUpdateClicked}
                    />
                </div>
            </LayoutScreen>
        </>
    );
};

export default EditObjectScreen;
