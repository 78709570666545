//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';

import { Modal } from 'react-overlays';
import styled    from 'styled-components';

import PropTypes            from '@components/PropTypes';
import useContextTranslator from '@hooks/ContextTranslator';
import Button               from '@stateless/atomic/Button';
import ButtonColor          from '@stateless/atomic/Button/ButtonColor';
import ButtonType           from '@stateless/atomic/Button/ButtonType';
import IconType             from '@stateless/atomic/Icon/IconType';
import ZIndexes             from '@styles/zIndexes.scss';

import styles from './styles.module.scss';

/* eslint-disable rulesdir/no-repeatedly-props-use-constant */
const Backdrop = styled('div')`
    position:         fixed;
    z-index:          ${(props) => props.$zIndex};
    top:              0;
    bottom:           0;
    left:             0;
    right:            0;
    background-color: #000000;
    opacity:          0.5;
`;

const PositionedModal = styled(Modal)`
    position:         fixed;
    width:            calc(100% - 150px);
    height:           calc(100% - 150px);
    z-index:          ${(props) => props.$zIndex};
    outline:          none;
    top:              50%;
    left:             50%;
    border-radius:    5px;
    transform:        translate(-50%, -50%);
    border:           1px solid #e5e5e5;
    background-color: white;
    box-shadow:       0 5px 15px rgba(0, 0, 0, 0.5);
    padding:          20px;

    @media (max-width: 768px)
    {
        width:  calc(100% - 25px);
        height: calc(100% - 150px);
    }
`;
/* eslint-enable rulesdir/no-repeatedly-props-use-constant */

const propTypes = {
    children: PropTypes.children,
    onClose:  PropTypes.func,
    show:     PropTypes.bool,
};

const OverlayModal = ({
    children = PropTypes.string,
    show     = PropTypes.bool,
    onClose  = PropTypes.func,
}) => {
    const [internalShow, setInternalShow] = useState(show);
    const translator                      = useContextTranslator('modal');
    const renderBackdrop                  = (props) => (
        <Backdrop
            $zIndex={ZIndexes.zindexmodals}
            {...props}
        />
    );

    useEffect(
        () => {
            setInternalShow(show);
        },
        [show],
    );

    function onHide() {
        setInternalShow(false);
    }

    function onCloseClicked(event) {
        onClose(event);
    }

    return (
        <div className="modal-example">
            <PositionedModal
                $zIndex={ZIndexes.zindexmodals}
                show={internalShow}
                onHide={onHide}
                renderBackdrop={renderBackdrop}
                aria-labelledby="modal-label"
            >
                <div className={styles.modalContent}>
                    {children}
                    <div className={styles.modalFooter}>
                        <Button
                            type={ButtonType.edgy}
                            color={ButtonColor.primary}
                            iconLeft={IconType.cross}
                            text={translator.t('close')}
                            onClick={onCloseClicked}
                        />
                    </div>
                </div>
            </PositionedModal>
        </div>
    );
};

OverlayModal.propTypes = propTypes;

export default OverlayModal;
