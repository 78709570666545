//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import { useState } from 'react';
import { useMemo }  from 'react';

import classNames from 'classnames';
import _          from 'lodash';
import styled     from 'styled-components';

import PropTypes             from '@components/PropTypes';
import { useTooltipContext } from '@connected/TooltipProvider';
import { svgColor }          from '@helper/StyledMixins';
import Button                from '@stateless/atomic/Button';
import ButtonColor           from '@stateless/atomic/Button/ButtonColor';
import Icon                  from '@stateless/atomic/Icon';
import IconType              from '@stateless/atomic/Icon/IconType';
import RowMenu               from '@stateless/atomic/RowMenu';

import styles from './styles.module.scss';

const propTypes = {
    children:            PropTypes.node,
    expandButtonColumns: PropTypes.number,
    expandButtonText:    PropTypes.string,
    menuItems:           PropTypes.array,
    rowColumns:          PropTypes.array,
};

/* eslint-disable rulesdir/no-repeatedly-props-use-constant */
const IconContainer = styled.div`
    background-color: ${(props) => props.theme.secondaryColor};
    ${(props) => svgColor(props.theme.buttonTextColor)}
`;
/* eslint-enable rulesdir/no-repeatedly-props-use-constant */

const Expandable = ({
    expandButtonColumns = 2,
    rowColumns          = [],
    expandButtonText    = '',
    children            = null,
    menuItems           = [],
}) => {
    const [expanded, setExpanded] = useState(false);
    const { addTooltip }          = useTooltipContext();

    function toggleExpandClicked() {
        setExpanded((current) => !current);
    }

    const headerRowStyle = useMemo(
        () => {
            let numberOfColumns = rowColumns.reduce((acc, column) => acc + column.columns, 0) + expandButtonColumns;

            if (menuItems.length > 0) {
                numberOfColumns += 1;
            }

            return {
                gridTemplateColumns: `repeat(${numberOfColumns}, 1fr)`,
            };
        },
        [
            rowColumns,
            expandButtonColumns,
        ],
    );

    function renderPrefixLine(prefixLine) {
        if (!prefixLine) {
            return null;
        }

        return (
            <div
                className={styles.prefixLine}
            />
        );
    }

    function renderRowColumn(items) {
        return items.map((item) => {
            let subtitle    = _.get(item, 'subtitle', null);
            const formatter = _.get(item, 'formatter', null);

            if (formatter) {
                subtitle = formatter(subtitle);
            }

            return (
                <div
                    className={styles.rowColumn}
                    key={item.title}
                >
                    {renderPrefixLine(item.prefixLine)}
                    <div>
                        <div>
                            {item.title}
                            {addTooltip(
                                item.infoText,
                                item.tooltipId,
                            )}
                        </div>
                        <div>
                            {subtitle}
                        </div>
                    </div>
                </div>
            );
        });
    }

    function renderExpandButton() {
        const colSpanStyle = {
            gridColumn: `span ${expandButtonColumns}`,
        };

        return (
            <div
                style={colSpanStyle}
                className={styles.expandButtonWrapper}
            >
                <Button
                    iconLeft={IconType.chevronRight}
                    color={ButtonColor.white}
                    text={expandButtonText}
                />
            </div>
        );
    }

    function renderRowColumnWrapper() {
        return rowColumns.map((column) => {
            const colSpanStyle = {
                gridColumn: `span ${column.columns}`,
            };

            return (
                <div
                    key={column.key}
                    style={colSpanStyle}
                    className={styles.rowColumnWrapper}
                >
                    {renderRowColumn(column.items)}
                </div>
            );
        });
    }

    function renderRowMenu() {
        if (menuItems.length === 0) {
            return null;
        }

        return (
            <RowMenu
                menuItems={menuItems}
            />
        );
    }

    return (
        <div className={styles.expandable}>
            <div
                className={classNames(
                    styles.expandableHeader,
                    {
                        [styles.expanded]: expanded,
                    },
                )}
                onClick={toggleExpandClicked}
            >
                <IconContainer className={styles.iconContainer}>
                    <Icon iconType={IconType.chevronRight} />
                </IconContainer>
                <div
                    style={headerRowStyle}
                    className={styles.headerRow}
                >
                    {renderRowColumnWrapper()}
                    {renderExpandButton()}
                    {renderRowMenu()}
                </div>
            </div>
            <div
                className={classNames(
                    styles.expandableWrapper,
                    {
                        [styles.expanded]: expanded,
                    },
                )}
            >
                <div className={styles.expandableContent}>
                    <div className={styles.separator} />
                    {children}
                </div>
            </div>
        </div>
    );
};

Expandable.propTypes = propTypes;

export default Expandable;
