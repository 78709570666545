//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';

import _         from 'lodash';
import { Modal } from 'react-overlays';
import styled    from 'styled-components';

import PropTypes from '@components/PropTypes';
import ZIndexes  from '@styles/zIndexes.scss';

import styles from './styles.module.scss';

/* eslint-disable rulesdir/no-repeatedly-props-use-constant */
const Backdrop = styled('div')`
    position:         fixed;
    z-index:          ${(props) => props.$zIndex};
    top:              0;
    bottom:           0;
    left:             0;
    right:            0;
    background-color: #000000;
    opacity:          0.8;
`;

const PositionedModal = styled(Modal)`
    position:         fixed;
    width:            90%;
    max-width:        500px;
    height:           auto;
    z-index:          ${(props) => props.$zIndex};
    outline:          none;
    top:              50%;
    left:             50%;
    border-radius:    5px;
    transform:        translate(-50%, -50%);
    border:           1px solid #e5e5e5;
    background-color: white;
    box-shadow:       0 5px 15px rgba(0, 0, 0, 0.5);
    padding:          20px;

    @media (min-width: 768px)
    {
        width:        50%;
        height:       auto;
        min-width:    450px;
        aspect-ratio: 2 / 1;
    }
`;
/* eslint-enable rulesdir/no-repeatedly-props-use-constant */

const propTypes = {
    children:         PropTypes.children,
    disableAutoClose: PropTypes.bool,
    footer:           PropTypes.node,
    onClose:          PropTypes.func,
    show:             PropTypes.bool,
};

const ModalOverlay = ({
    show             = false,
    onClose          = _.noop,
    children         = null,
    disableAutoClose = false,
    footer           = null,
}) => {
    const [internalShow, setInternalShow] = useState(show);
    const renderBackdrop                  = (props) => (
        <Backdrop
            $zIndex={ZIndexes.zindexmodals}
            {...props}
        />
    );

    useEffect(
        () => {
            setInternalShow(show);
        },
        [show],
    );

    function onHide(event) {
        if (!disableAutoClose) {
            setInternalShow(false);
            onClose(event);
        }
    }

    return (
        <div className={styles.modal}>
            <PositionedModal
                $zIndex={ZIndexes.zindexmodals}
                show={internalShow}
                onHide={onHide}
                onBackdropClick={onHide}
                renderBackdrop={renderBackdrop}
            >
                <div className={styles.modalContent}>
                    <div>
                        <div>
                            {children}
                        </div>
                    </div>
                    <div className={styles.modalFooter}>
                        {footer}
                    </div>
                </div>
            </PositionedModal>
        </div>
    );
};

ModalOverlay.propTypes = propTypes;

export default ModalOverlay;
