//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n from 'i18next';

export const PropertyType = Object.freeze({
    monument: 'MONUMENT',
    care:     'CARE',
    new:      'NEW',
    existing: 'EXISTING',
});

export const getPropertyTypeOptions = () => {
    return Object.values(PropertyType).map((type) => {
        return {
            label: I18n.t(`propertyType.${type}`),
            value: type,
        };
    });
};

export default PropertyType;
