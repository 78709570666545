//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { PropTypes as BasePropTypes } from 'prop-types';

import { TestIdPrefixes } from '@constants/TestIds';
import { TestIds }        from '@constants/TestIds';
import ButtonColor        from '@stateless/atomic/Button/ButtonColor';
import ButtonType         from '@stateless/atomic/Button/ButtonType';
import HeadlineType       from '@stateless/atomic/Headline/HeadlineType';
import IconType           from '@stateless/atomic/Icon/IconType';

class PropTypes {
    static oneOfObjectKeys = (object) => {
        return BasePropTypes.oneOf(Object.keys(object));
    };

    static oneOfObjectValues = (object) => {
        return BasePropTypes.oneOf(Object.values(object));
    };

    static cssUnits = [
        BasePropTypes.number,
        BasePropTypes.string,
    ];

    static children = BasePropTypes.oneOfType([
        BasePropTypes.arrayOf(BasePropTypes.node),
        BasePropTypes.node,
    ]);

    static cssHeight = BasePropTypes.oneOfType(PropTypes.cssUnits);

    static cssWidth = BasePropTypes.oneOfType(PropTypes.cssUnits);

    static date = BasePropTypes.oneOfType([
        BasePropTypes.string,
        BasePropTypes.instanceOf(Date),
        BasePropTypes.number,
    ]);

    static image = BasePropTypes.oneOfType([
        BasePropTypes.string,
        BasePropTypes.object,
    ]);

    static numberOrString = BasePropTypes.oneOfType([
        BasePropTypes.string,
        BasePropTypes.number,
    ]);

    static oneOfTestIds = this.oneOfObjectValues(TestIds);

    static oneOfTestIdPrefix = this.oneOfObjectValues(TestIdPrefixes);

    static buttonType = this.oneOfObjectValues(ButtonType);

    static buttonColor = this.oneOfObjectValues(ButtonColor);

    static headlineType = this.oneOfObjectValues(HeadlineType);

    static houseNumber = BasePropTypes.oneOfType([
        BasePropTypes.number,
        BasePropTypes.string,
    ]);

    static iconType = this.oneOfObjectKeys(IconType);

    static item = BasePropTypes.shape({
        highlighted: BasePropTypes.bool,
        id:          BasePropTypes.node,
        name:        BasePropTypes.string,
    });

    static geoLocation = BasePropTypes.shape({
        latitude:  BasePropTypes.number,
        longitude: BasePropTypes.number,
    });

    static selectOption = BasePropTypes.shape({
        value: BasePropTypes.oneOfType([
            BasePropTypes.number,
            BasePropTypes.string,
        ]),
        label: BasePropTypes.oneOfType([
            BasePropTypes.number,
            BasePropTypes.string,
        ]),
    });

    static selectOptions = BasePropTypes.arrayOf(PropTypes.selectOption);
}

export default Object.assign(PropTypes, BasePropTypes);
