//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';

import I18n   from 'i18next';
import _      from 'lodash';
import styled from 'styled-components';

import PropTypes            from '@components/PropTypes';
import CurrencyInputWrapper from '@stateless/atomic/CurrencyInputWrapper';
import IconType             from '@stateless/atomic/Icon/IconType';
import LabeledInput         from '@stateless/atomic/LabeledInput';
import LabeledInputType     from '@stateless/atomic/LabeledInput/LabeledInputType';
import TextSliderType       from '@stateless/composed/TextSliderInput/TextSliderType';

import styles from './styles.module.scss';

const propTypes = {
    fixedDecimalLength: PropTypes.number,
    label:              PropTypes.string,
    maximumValue:       PropTypes.number.isRequired,
    minimumValue:       PropTypes.number.isRequired,
    onChange:           PropTypes.func,
    step:               PropTypes.number,
    type:               PropTypes.string,
    value:              PropTypes.numberOrString,
};

const SliderInput = styled.input`
    background-color: ${(props) => props.theme.secondaryColor} !important;
`;

const TextSliderInput = ({
    fixedDecimalLength = 2,
    label              = '',
    maximumValue       = 0,
    minimumValue       = 0,
    onChange           = _.noop,
    step               = 1,
    value              = 0,
    type               = TextSliderType.percentage,
}) => {
    const [localValue, setLocalValue] = useState(value);
    const [isFocused, setIsFocused]   = useState(false);
    const handleFocus                 = () => {
        setIsFocused(true);
    };
    const handleBlur                  = () => {
        setIsFocused(false);
    };

    function getValueInSideRange(valueToCheck) {
        const numberValue = _.toNumber(valueToCheck);

        if (numberValue < minimumValue) {
            return minimumValue;
        }

        if (numberValue > maximumValue) {
            return maximumValue;
        }

        return valueToCheck;
    }

    function applyMinimumAndMaximumValue(valueToCheck) {
        const numberValue = _.toNumber(valueToCheck);

        if (numberValue < minimumValue) {
            return minimumValue;
        }

        if (numberValue > maximumValue) {
            return maximumValue;
        }

        return valueToCheck;
    }

    useEffect(
        () => {
            if (!isFocused) {
                const valueToUse = getValueInSideRange(value);

                setLocalValue(valueToUse);
            }
        },
        [value, isFocused],
    );

    function handleSliderChange(event) {
        const newValue = parseFloat(event.target.value).toFixed(fixedDecimalLength);

        setLocalValue(newValue);
        onChange(newValue);
    }

    function handleInputChange(textValue, newValue, stringValue) {
        const adjustedValue = applyMinimumAndMaximumValue(stringValue);

        setLocalValue(adjustedValue);
        onChange(adjustedValue);
    }

    function getPrefix() {
        if (type === TextSliderType.intervalInYears) {
            return I18n.t('format.intervalInYears.prefix');
        }

        return null;
    }

    function getSuffix() {
        if (type === TextSliderType.intervalInYears) {
            return I18n.t('format.intervalInYears.suffix');
        }

        return I18n.t('format.percentSuffix');
    }

    return (
        <div className={styles.labeledRangeTextInput}>
            <LabeledInput
                type={LabeledInputType.light}
                label={label}
                name={label}
                postIconType={IconType.pen}
                input={<CurrencyInputWrapper
                    value={localValue}
                    onChange={handleInputChange}
                    intlConfig={null}
                    decimalsLimit={fixedDecimalLength}
                    onKeyDown={handleFocus}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    formatting={false}
                    suffix={getSuffix()}
                    prefix={getPrefix()}
                />}
            />
            <div className={styles.sliderWrapper}>
                <SliderInput
                    onChange={handleSliderChange}
                    value={localValue}
                    max={maximumValue}
                    min={minimumValue}
                    step={step}
                    type={'range'}
                />
            </div>
        </div>
    );
};

TextSliderInput.propTypes = propTypes;

export default TextSliderInput;
