//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes from '@components/PropTypes';

import styles from './styles.module.scss';

const propTypes = {
    children: PropTypes.children,
};

const Table = ({
    children = null,
}) => {
    return (
        <table
            cellPadding={0}
            cellSpacing={0}
            className={styles.table}
        >
            {children}
        </table>
    );
};

Table.propTypes = propTypes;

export default Table;
