//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import 'core-js';
import '@language/Language';
import 'moment/locale/de';

import { ConnectedRouter } from 'connected-react-router';
import I18n                from 'i18next';
import moment              from 'moment';
import { createRoot }      from 'react-dom/client';
import ReactGA             from 'react-ga4';
import { Provider }        from 'react-redux';
import { PersistGate }     from 'redux-persist/integration/react';
import * as yup            from 'yup';

import AppThemeProvider        from '@connected/AppThemeProvider';
import ContractWatcher         from '@connected/ContractWatcher';
import LoadingOverlay          from '@connected/LoadingOverlay';
import MainRouter              from '@connected/MainRouter';
import StagingLoginOverlay     from '@connected/StagingLoginOverlay';
import TooltipManager          from '@connected/TooltipManager';
import TooltipProvider         from '@connected/TooltipProvider';
import WindowVisibilityWatcher from '@connected/WindowVisibilityWatcher';
import Environment             from '@helper/Environment';
import ImageStorage            from '@helper/ImageStorage';
import Language                from '@helper/Language';
import CacheInvalidator        from '@stateless/atomic/CacheInvalidator';
import ImageStorageProvider    from '@stateless/atomic/ImageStorageProvider';
import CalculationSaveConfirm  from '@stateless/composed/CalculationSaveConfirm';
import NotificationHandler     from '@stateless/composed/NotificationHandler';
import NotificationPosition    from '@stateless/composed/NotificationHandler/NotificationPosition';
import { history }             from '@store';
import createStore             from '@store';

import * as serviceWorker from './serviceWorker';
import styles             from './styles.module.scss';

// eslint-disable-next-line rulesdir/format-import-linter -- Required for the tooltip
import 'react-tooltip/dist/react-tooltip.css';

import '@external/sentry';
import '@store/miscellaneous/immutability-helper-extensions';

ImageStorage.initializeStore();

moment.locale(Language.getDefaultLanguageShort());

if (Environment.isProduction()) {
    ReactGA.initialize('G-K2RQK32608');
} else if (Environment.isStaging()) {
    ReactGA.initialize('G-GXQPKT3H5Q');
}

const { store, persistor } = createStore();
const container            = document.getElementById('root');
const root                 = createRoot(container);

yup.setLocale({
    string: {
        max: I18n.t('yup.string.max'),
    },
});

root.render((
    <Provider store={store}>
        <PersistGate
            persistor={persistor}
            loading={null}
        >
            <ImageStorageProvider />
            <CacheInvalidator />
            {/*
                If you don't want to automatically clear the browsers cache and update the
                application you can switch <CacheInvalidator /> by <CacheInvalidatorWithPrompt />
                to ask the user if he wants to update.
            */}
            <AppThemeProvider>
                <TooltipProvider>
                    <StagingLoginOverlay>
                        <LoadingOverlay />
                        <WindowVisibilityWatcher />
                        <ConnectedRouter history={history}>
                            <div className={styles.appContent}>
                                <NotificationHandler
                                    position={NotificationPosition.right}
                                />
                                <CalculationSaveConfirm />
                                <MainRouter />
                                <ContractWatcher />
                                <TooltipManager />
                            </div>
                        </ConnectedRouter>
                    </StagingLoginOverlay>
                </TooltipProvider>
            </AppThemeProvider>
        </PersistGate>
    </Provider>
));

serviceWorker.register();
