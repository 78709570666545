//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import I18n                   from 'i18next';
import update                 from 'immutability-helper';
import _                      from 'lodash';
import { bindActionCreators } from 'redux';

import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';

const initialState = Object.freeze({
    news: [],
});

const newsSlice = createSlice({
    name:     'news',
    initialState,
    reducers: {
        fetchNews:          LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loading.news')),
        fetchNewsFailed:    LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        fetchNewsSucceeded: LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                news: {
                    $set: _.get(action, 'payload.news', []),
                },
            });
        }),
        reset:              () => {
            return {
                ...initialState,
            };
        },
    },
});

export const NewsActions = newsSlice.actions;

export const NewsReducer = newsSlice.reducer;

export const useNews = (dispatch) => bindActionCreators(NewsActions, dispatch);

export default newsSlice;
