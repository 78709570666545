//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import PropTypes          from '@components/PropTypes';
import TableHeaderRowType from '@stateless/atomic/TableHeaderRow/TableHeaderRowType';

import styles from './styles.module.scss';

const propTypes = {
    children: PropTypes.children,
    columns:  PropTypes.number,
    type:     PropTypes.oneOfObjectValues(Object.values(TableHeaderRowType)),
};

const TableHeaderRow = ({
    children = null,
    columns  = 1,
    type     = TableHeaderRowType.default,

}) => {
    const gridColumnStyle = {
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
    };

    return (
        <tr
            className={classNames(
                styles.tableHeaderRow,
                {
                    [styles.filled]: type === TableHeaderRowType.filled,
                },
            )}
            style={gridColumnStyle}
        >
            {children}
        </tr>
    );
};

TableHeaderRow.propTypes = propTypes;

export default TableHeaderRow;
