//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice } from '@reduxjs/toolkit';

const initialState = Object.freeze({});

const preloadSlice = createSlice({
    name:     'preload',
    initialState,
    reducers: {
        preLoadData: (state, action) => {
        },
    },
});

export const PreloadActions = preloadSlice.actions;

export const PreloadReducer = preloadSlice.reducer;

export default preloadSlice;
