//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes from '@components/PropTypes';
import styles    from '@stateless/atomic/TableBody/styles.module.scss';

const propTypes = {
    children: PropTypes.children,
};

const TableBody = ({
    children = null,
}) => {
    return (
        <tbody className={styles.tableBody}>
            {children}
        </tbody>
    );
};

TableBody.propTypes = propTypes;

export default TableBody;
