//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// eton start
import { createSlice }        from '@reduxjs/toolkit';
import update                 from 'immutability-helper';
import { bindActionCreators } from 'redux';

const initialState = Object.freeze({
    windowHasFocus:  true,
    windowIsVisible: true,
});

const windowSlice = createSlice({
    name:     'window',
    initialState,
    reducers: {
        windowGotFocus:      (state) => {
            return update(state, {
                windowHasFocus: {
                    $set: true,
                },
            });
        },
        windowLostFocus:     (state) => {
            return update(state, {
                windowHasFocus: {
                    $set: false,
                },
            });
        },
        windowBecameHidden:  (state) => {
            return update(state, {
                windowIsVisible: {
                    $set: false,
                },
            });
        },
        windowBecameVisible: (state) => {
            return update(state, {
                windowIsVisible: {
                    $set: true,
                },
            });
        },
    },
});

export const WindowActions = windowSlice.actions;

export const WindowReducer = windowSlice.reducer;

export const useWindow = (dispatch) => bindActionCreators(WindowActions, dispatch);

export default windowSlice;
