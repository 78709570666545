//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import styled from 'styled-components';

import PropTypes   from '@components/PropTypes';
import ColorHelper from '@helper/Color';
import NewsParser  from '@helper/NewsParser';

import styles from './styles.module.scss';

const propTypes = {
    newsItem: PropTypes.object,
};

/* eslint-disable rulesdir/no-repeatedly-props-use-constant */
const WidgetWrapper = styled.div`
    background-color: ${(props) => props.theme.primaryColor} !important;
    border:           1px solid ${(props) => ColorHelper.getDarkerColor(props.theme.primaryColor, 2)} !important;
`;

const NewsWrapper = styled.div`
    h1,
    h2,
    h3,
    h4,
    ul,
    ol,
    > p
    {
        color: ${(props) => props.theme.textColor} !important;
    }

    ul,
    ol,
    > p
    {
        font-size: 14px;
    }

    ul,
    ol
    {
        margin-left: 20px;
    }
`;
/* eslint-enable rulesdir/no-repeatedly-props-use-constant */

const NewsWidget = ({
    newsItem = null,
}) => {
    const newsParser = new NewsParser();

    if (!newsItem) {
        return null;
    }

    return (
        <WidgetWrapper className={styles.newsWidget}>
            <NewsWrapper className={styles.contentWrapper}>
                {newsParser.parseNewsToWidgetContent(newsItem.textContent)}
            </NewsWrapper>
        </WidgetWrapper>
    );
};

NewsWidget.propTypes = propTypes;

export default NewsWidget;
