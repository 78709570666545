//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { push }       from 'connected-react-router';
import _              from 'lodash';
import { takeLatest } from 'redux-saga/effects';
import { put }        from 'redux-saga/effects';
import { call }       from 'redux-saga/effects';

import * as Api          from '@api/index';
import Routes            from '@constants/Routes';
import UnitFields        from '@constants/UnitFields';
import Notification      from '@helper/Notification';
import SagaStateHelper   from '@helper/SagaStateHelper';
import { ObjectActions } from '@slices/object';
import { UnitActions }   from '@slices/unit';
import NumberMapper      from '@store/helper/NumberMapper';
import selectEditUnit    from '@store/selectors/unit';

function* saveUnit() {
    const unit         = yield SagaStateHelper.selector(selectEditUnit);
    const mappedUnit   = {
        ...NumberMapper.mapFieldsToNumber(
            unit,
            [
                UnitFields.purchasePrice,
                UnitFields.monthlyRent,
                UnitFields.spaceInSquareMeters,
                UnitFields.roomCount,
            ],
        ),
        [UnitFields.calculationData]:  NumberMapper.mapFieldsToNumber(
            _.get(unit, UnitFields.calculationData, {}),
            [
                UnitFields.calculationDataInventoryValue,
                UnitFields.calculationDataRenovationCosts,
                UnitFields.calculationDataPurchasePrice,
                UnitFields.calculationDataMonthlyRent,
            ],
        ),
        [UnitFields.carParkingSpaces]: _.map(
            _.get(unit, UnitFields.carParkingSpaces, []),
            (carParkingSpace) => {
                return NumberMapper.mapFieldsToNumber(
                    carParkingSpace,
                    [
                        UnitFields.carParkingSpacePurchasePrice,
                        UnitFields.carParkingSpaceMonthlyRent,
                    ],
                );
            },
        ),
    };
    const response     = yield call(
        Api.context.unit.saveUnit,
        mappedUnit,
    );
    const isNewPayload = {
        isNew: !_.get(unit, 'iri'),
    };

    if (response.ok) {
        yield put(UnitActions.saveUnitSucceeded(isNewPayload));
    } else {
        yield put(UnitActions.saveUnitFailed(isNewPayload));
    }
}

function getTranslation(action, translationKey) {
    const prefix = (
        _.get(action, 'payload.isNew') ?
            'createUnit' :
            'updateUnit'
    );

    return `${prefix}.${translationKey}`;
}

function* saveUnitFailed(action) {
    Notification.error(getTranslation(action, 'error'));
}

function* saveUnitSucceeded(action) {
    Notification.success(getTranslation(action, 'success'));
    yield put(push(Routes.objects));
}

function* deleteUnit(action) {
    const unit     = _.get(action, 'payload.unit');
    const response = yield call(
        Api.context.unit.deleteUnit,
        _.get(unit, 'iri'),
    );

    if (response.ok) {
        yield put(UnitActions.deleteUnitSucceeded());
    } else {
        yield put(UnitActions.deleteUnitFailed());
    }
}

function* deleteUnitFailed() {
    Notification.error('deleteUnit.error');
}

function* deleteUnitSucceeded() {
    Notification.success('deleteUnit.success');
    yield put(ObjectActions.fetchObjects());
}

function* editUnit() {
    yield put(push(Routes.editUnit));
}

const callSagas = () => {
    return [
        // @formatter:off
        takeLatest([UnitActions.saveUnit().type],            saveUnit),
        takeLatest([UnitActions.saveUnitFailed().type],      saveUnitFailed),
        takeLatest([UnitActions.saveUnitSucceeded().type],   saveUnitSucceeded),
        takeLatest([UnitActions.deleteUnit().type],          deleteUnit),
        takeLatest([UnitActions.deleteUnitFailed().type],    deleteUnitFailed),
        takeLatest([UnitActions.deleteUnitSucceeded().type], deleteUnitSucceeded),
        takeLatest([
            UnitActions.editUnit().type,
            UnitActions.createNewUnit().type,
        ],                                                  editUnit),
        // @formatter:on
    ];
};

export default {
    callSagas,
};
