//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';
import { useMemo }   from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory }  from 'react-router';

import CalculationSendEmailModal        from '@connected/CalculationSendEmailModal';
import CalculationFields                from '@constants/CalculationFields';
import CalculationSteps                 from '@constants/CalculationSteps';
import Routes                           from '@constants/Routes';
import Notification                     from '@helper/Notification';
import { useCalculation }               from '@slices/calculation';
import Button                           from '@stateless/atomic/Button';
import ButtonColor                      from '@stateless/atomic/Button/ButtonColor';
import ButtonType                       from '@stateless/atomic/Button/ButtonType';
import IconType                         from '@stateless/atomic/Icon/IconType';
import selectCurrentCalculation         from '@store/selectors/calculation';
import { selectCurrentCalculationStep } from '@store/selectors/calculation';
import selectToken                      from '@store/selectors/user';

import styles from './styles.module.scss';

const propTypes = {};

const CalculationNavigationBar = () => {
    const history                               = useHistory();
    const token                                 = useSelector(selectToken);
    const currentRoute                          = history.location.pathname;
    const orderedCalculationRoutes              = [
        Routes.calculationCustomer,
        Routes.calculationObject,
        Routes.calculationFinance,
        Routes.calculationResult,
    ];
    const mappedRoutes                          = {
        [Routes.calculationCustomer]: CalculationSteps.customer,
        [Routes.calculationObject]:   CalculationSteps.object,
        [Routes.calculationFinance]:  CalculationSteps.finance,
        [Routes.calculationResult]:   CalculationSteps.result,
    };
    const dispatch                              = useDispatch();
    const calculation                           = useSelector(selectCurrentCalculation);
    const step                                  = useSelector(selectCurrentCalculationStep);
    const unit                                  = _.get(calculation, CalculationFields.unit);
    const customer                              = _.get(calculation, CalculationFields.customer);
    const calculationActions                    = useCalculation(dispatch);
    const [triggerSendMail, setTriggerSendMail] = useState('');
    const currentIndex                          = useMemo(
        () => orderedCalculationRoutes.indexOf(currentRoute),
        [currentRoute, orderedCalculationRoutes],
    );
    const calculationValidation                 = [
        {
            index:      0,
            condition:  !!customer,
            messageKey: 'calculationCustomerMissing',
        },
        {
            index:      1,
            condition:  !!unit,
            messageKey: 'calculationUnitMissing',
        },
    ];

    useEffect(
        () => {
            const route = _.findKey(mappedRoutes, (value) => {
                return value === step;
            });

            if (
                route &&
                route !== currentRoute &&
                token
            ) {
                history.push(route);
            }
        },
        [step],
    );

    function validateCalculationStep() {
        const validation = calculationValidation.find((item) => {
            return item.index === currentIndex;
        });

        if (
            validation &&
            !validation.condition
        ) {
            Notification.error(validation.messageKey);

            return false;
        }

        return true;
    }

    function onSaveButtonClicked() {
        calculationActions.saveCalculation();
    }

    function onNextButtonClick() {
        const nextRoute = orderedCalculationRoutes[currentIndex + 1];

        if (!validateCalculationStep()) {
            return;
        }

        if (nextRoute) {
            history.push(nextRoute);
            calculationActions.setStep({
                step: mappedRoutes[nextRoute],
            });
        }
    }

    function onPreviousButtonClick() {
        const previousRoute = orderedCalculationRoutes[currentIndex - 1];

        if (previousRoute) {
            history.push(previousRoute);
            calculationActions.setStep({
                step: mappedRoutes[previousRoute],
            });
        }
    }

    function onDownloadButtonClicked() {
        calculationActions.downloadCalculationPDF();
    }

    function onEMailButtonClicked() {
        setTriggerSendMail(_.uniqueId());
    }

    function renderNextButton() {
        return (
            <Button
                type={ButtonType.edgy}
                iconLeft={IconType.circleArrowRight}
                text={I18n.t('forward')}
                onClick={onNextButtonClick}
            />
        );
    }

    function renderPreviousButton() {
        return (
            <Button
                type={ButtonType.edgy}
                color={ButtonColor.white}
                iconLeft={IconType.circleArrowLeft}
                text={I18n.t('backward')}
                onClick={onPreviousButtonClick}
            />
        );
    }

    function renderResultButtonBar() {
        return (
            <>
                <Button
                    type={ButtonType.edgy}
                    color={ButtonColor.white}
                    iconLeft={IconType.save}
                    text={I18n.t('save')}
                    onClick={onSaveButtonClicked}
                />
                <Button
                    type={ButtonType.edgy}
                    color={ButtonColor.white}
                    iconLeft={IconType.download}
                    text={I18n.t('downloadPdf')}
                    onClick={onDownloadButtonClicked}
                />
                <Button
                    type={ButtonType.edgy}
                    color={ButtonColor.white}
                    iconLeft={IconType.send}
                    text={I18n.t('sendEmail')}
                    onClick={onEMailButtonClicked}
                />
            </>
        );
    }

    function renderButton() {
        switch (currentRoute) {
            case Routes.calculationCustomer:
                return (
                    <>
                        {renderNextButton()}
                    </>
                );
            case Routes.calculationObject:
            case Routes.calculationFinance:
                return (
                    <>
                        {renderPreviousButton()}
                        {renderNextButton()}
                    </>
                );
            case Routes.calculationResult:
            default:
                return (
                    <>
                        {renderPreviousButton()}
                        {renderResultButtonBar()}
                    </>
                );
        }
    }

    return (
        <div className={styles.calculationNavigationBar}>
            {renderButton()}
            <CalculationSendEmailModal
                triggerOpen={triggerSendMail}
            />
        </div>
    );
};

CalculationNavigationBar.propTypes = propTypes;

export default CalculationNavigationBar;
