//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const objectFields = Object.freeze({
    calculationData:  'calculationData',
    carParkingSpaces: 'carParkingSpaces',
});

export default Object.freeze({
    object:                         'property',
    number:                         'number',
    roomCount:                      'roomCount',
    spaceInSquareMeters:            'spaceInSquareMeters',
    status:                         'status',
    showUnitInCalculator:           'showUnitInCalculator',
    unitCarParkingPurchasePrice:    'carParkingPurchasePrice',
    singlePurchasePrice:            'singlePurchasePrice',
    locationOnProperty:             'locationOnProperty',
    purchasePricePerSquareMeter:    'purchasePricePerSquareMeter',
    calculationData:                'calculationData',
    carParkingSpaces:               'carParkingSpaces',
    purchasePriceField:             'purchasePrice',
    monthlyRentField:               'monthlyRent',
    purchasePrice:                  [objectFields.calculationData, 'purchasePrice'],
    monthlyRent:                    [objectFields.calculationData, 'monthlyRent'],
    inventoryValue:                 [objectFields.calculationData, 'inventoryValue'],
    renovationCosts:                [objectFields.calculationData, 'renovationCosts'],
    calculationDataPurchasePrice:   'purchasePrice',
    calculationDataMonthlyRent:     'monthlyRent',
    calculationDataInventoryValue:  'inventoryValue',
    calculationDataRenovationCosts: 'renovationCosts',
    carParkingSpacePurchasePrice:   'purchasePrice',
    carParkingSpaceMonthlyRent:     'monthlyRent',
    carParkingSpaceType:            'parkingType',
    singleMonthlyRent:              'singleMonthlyRent',
    unitCarParkingMonthlyRent:      'carParkingMonthlyRent',
    monthlyRentPerSquareMeter:      'monthlyRentPerSquareMeter',
    returnYield:                    'returnYield',
    objectType:                     'propertyType',
});
