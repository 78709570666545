//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React from 'react';

import { ProcessNodeDefinitions }      from 'html-to-react';
import { Parser as HtmlToReactParser } from 'html-to-react';

import Button     from '@stateless/atomic/Button';
import ButtonType from '@stateless/atomic/Button/ButtonType';
import Headline   from '@stateless/atomic/Headline';
import IconType   from '@stateless/atomic/Icon/IconType';

const processNodeDefinitions = new ProcessNodeDefinitions();

const headlineInstruction = {
    shouldProcessNode(node) {
        return (
            node.name === 'h1' ||
            node.name === 'h2' ||
            node.name === 'h3' ||
            node.name === 'h4'
        );
    },
    processNode(node, children, index) {
        return (
            <Headline
                title={node.children[0].data}
                type={node.name}
            />
        );
    },
};

const linkInstruction = {
    shouldProcessNode(node) {
        return node.name === 'a';
    },
    processNode(node) {
        const onClick = () => {
            window.open(node.attribs.href, '_self');
        };

        return (
            <Button
                type={ButtonType.edgy}
                text={node.children[0].data}
                iconLeft={IconType.circleArrowRight}
                onClick={onClick}
            />
        );
    },
};

const defaultInstruction = {
    shouldProcessNode(node) {
        return true;
    },
    processNode: processNodeDefinitions.processDefaultNode,
};

const processingInstructions = [
    headlineInstruction,
    linkInstruction,
    defaultInstruction,
];

class NewsParser {
    constructor() {
        this.htmlToReactParser = new HtmlToReactParser();
    }

    isValidNode() {
        return true;
    }

    parseNewsToWidgetContent(newsHtml) {
        return this.htmlToReactParser.parseWithInstructions(
            newsHtml,
            this.isValidNode,
            processingInstructions,
        );
    }
}

export default NewsParser;
