//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default Object.freeze({
    API_TOKENS:                    'api/tokens',
    API_USER:                      'api/user',
    API_USERS:                     'api/users',
    API_USERS_PASSWORD_RESET:      'api/users/reset-password',
    API_USERS_SET_NEW_PASSWORD:    'api/users/set-new-password',
    API_UNITS_WIDGET_URL:          'api/units/widget',
    API_SUBSCRIPTION_OPTIONS_URL:  'api/subscription-options',
    API_CONTACT_FORM_URL:          'api/contact-forms',
    API_COLOR_SETTINGS_WIDGET_URL: 'api/color-settings/widget',
    API_COLOR_SETTINGS_URL:        'api/color-settings',
    API_CALCULATION_WIDGET_URL:    'api/calculation/cashflow',
    API_CALCULATIONS:              'api/calculations',
    API_CALCULATIONS_SEND_MAIL:    (id) => `api/calculations/${id}/send-mail`,
    API_CALCULATIONS_DOWNLOAD:     (id) => `/calculation/pdf/${id}/pdf-external`,
    API_PROPERTY_URL:              'api/properties',
    API_DASHBOARD:                 'api/dashboard',
    API_UNIT_URL:                  'api/units',
    API_CUSTOMER_URL:              'api/customers',
    API_NEWS:                      'api/news',
});
