//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n from 'i18next';
import _    from 'lodash';

import CurrencyInputWrapper from '@stateless/atomic/CurrencyInputWrapper';
import LabeledInput         from '@stateless/atomic/LabeledInput';
import LabeledInputType     from '@stateless/atomic/LabeledInput/LabeledInputType';
import TextInputType        from '@stateless/atomic/TextInput/TextInputType';
import TextSliderInput      from '@stateless/composed/TextSliderInput';
import TextSliderType       from '@stateless/composed/TextSliderInput/TextSliderType';

class InputRenderHelper {
    constructor({
        validations,
        data,
        onUpdateData = _.noop,
    }) {
        this.validations  = validations;
        this.data         = data;
        this.onUpdateData = onUpdateData;
    }

    getError(field) {
        let fieldToUse = field;

        if (_.isArray(field)) {
            fieldToUse = _.reduce(
                field,
                (result, part, index) => {
                    if (typeof part === 'number') {
                        return `${result}[${part}]`;
                    }

                    return (
                        index === 0 ?
                            part :
                            `${result}.${part}`
                    );
                },
                '',
            );
        }

        return _.has(this.validations, fieldToUse);
    }

    renderCurrencyInput(props) {
        return this.renderCustomCurrencyInput({
            ...props,
            intlConfig:    {
                locale:   'de-DE',
                currency: 'EUR',
            },
            formatToFixed: false,
        });
    }

    renderCustomCurrencyInput({
        label,
        field,
        value,
        required,
        onChange,
        infoText,
        type = LabeledInputType.light,
        suffix = null,
        intlConfig = null,
        disabled = false,
        preIconType = null,
        color = null,
        textInputType = TextInputType.text,
        formatToFixed = false,
    }) {
        return (
            <LabeledInput
                label={label}
                type={type}
                required={required}
                error={this.getError(field)}
                infoText={infoText}
                tooltipId={field}
                preIconType={preIconType}
                color={color}
                textInputType={textInputType}
                input={
                    <CurrencyInputWrapper
                        value={value ?? _.get(this.data, field)}
                        onChange={onChange ?? this.onUpdateData(field)}
                        required={required}
                        intlConfig={intlConfig}
                        suffix={suffix}
                        disabled={disabled}
                        formatToFixed={formatToFixed}
                    />
                }
            />
        );
    }

    renderNumberInput(props) {
        return this.renderCustomCurrencyInput({
            ...props,
        });
    }

    renderPercentInput(props) {
        return this.renderCustomCurrencyInput({
            ...props,
            suffix: I18n.t('format.percentSuffix'),
        });
    }

    renderTextInput({
        label,
        field,
        value,
        required,
        onChange,
        type = LabeledInputType.light,
        formatter = null,
        disabled = false,
        textInputType = TextInputType.text,
    }) {
        let formattedValue = value ?? _.get(this.data, field);

        if (formatter) {
            formattedValue = formatter(formattedValue);
        }

        return (
            <LabeledInput
                label={label}
                type={type}
                error={this.getError(field)}
                required={required}
                disabled={disabled}
                textInputType={textInputType}
                value={formattedValue}
                onChange={onChange ?? this.onUpdateData(field)}
            />
        );
    }

    renderTextSlider({
        field,
        label,
        maximumValue,
        step,
        minimumValue,
        value,
        onChange,
        type = null,
    }) {
        const decimalLength = (
            type === TextSliderType.intervalInYears ?
                0 :
                2
        );

        return (
            <TextSliderInput
                label={label}
                maximumValue={maximumValue}
                step={step}
                minimumValue={minimumValue}
                value={value ?? _.get(this.data, field)}
                onChange={onChange ?? this.onUpdateData(field)}
                type={type}
                fixedDecimalLength={decimalLength}
            />
        );
    }
}

export default InputRenderHelper;
