//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _           from 'lodash';
import { Tooltip } from 'react-tooltip';
import styled      from 'styled-components';

import Icon     from '@stateless/atomic/Icon';
import IconType from '@stateless/atomic/Icon/IconType';

import styles from './styles.module.scss';

/* eslint-disable rulesdir/no-repeatedly-props-use-constant */
const InfoTextWrapper = styled.div`
    svg
    {
        .fill
        {
            fill: ${(props) => props.theme.primaryColor};
        }

        .fillSecondary
        {
            fill: ${(props) => props.theme.textColor};
        }
    }
`;

/* eslint-enable rulesdir/no-repeatedly-props-use-constant */

class TooltipBundler {
    constructor({
        infoText,
        tooltipId = _.uniqueId('tooltip_'),
    }) {
        this.infoText  = infoText;
        this.tooltipId = tooltipId;
    }

    renderInfoIcon() {
        if (this.infoText) {
            return (
                <InfoTextWrapper
                    data-tooltip-id={this.tooltipId}
                    className={styles.labelInfoTextWrapper}
                >
                    <Icon iconType={IconType.information} />
                </InfoTextWrapper>
            );
        }

        return null;
    }

    renderInfoTooltip() {
        if (this.infoText) {
            return (
                <Tooltip
                    id={this.tooltipId}
                    place={'top'}
                    variant={'info'}
                    content={this.infoText}
                    className={styles.labelInfoText}
                    disableStyleInjection={true}
                />
            );
        }

        return null;
    }
}

export default TooltipBundler;
